<!--
 * @Description  : 文章列表组件
-->
<template>
  <div class="hx-main pad-news" :style="`min-height: ${pageHeight};`">
    <div class="pad-cover">
      <span class="text-title">
        {{ title }}
      </span>
      <img src="./../../assets/img/bg-news.jpg" alt="" class="img-cover">
    </div>
    <div class="hx-container">
      <div class="pad-news-list" v-if="articles.length">
        <div class="row-news" :key="idx" v-for="(item, idx) in articles" @click="doOpen(item.path)">
          <span class="text-title">{{ item.title }}</span>
          <span class="text-date">{{ item.date }}</span>
        </div>
      </div>
      <div class="emptyset" v-else>
        暂无{{ title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    articles: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      pageHeight: 0
    }
  },
  mounted () {
    this.$_init()
  },
  methods: {
    $_init () {
      this.pageHeight = (document.body.clientHeight - 80) + 'px'
    },
    doOpen (path) {
      this.$toRoute(path)
    }
  }
}
</script>

<style lang="less" scoped>
@import "./../index.less";
.pad-news {
  padding-bottom: @pm-lg * 1.5;
  display: block;
  width: 100%;
  .emptyset {
    font-size: 32px;
    font-weight: 300;
    color: #aaa;
    line-height: 3;
    width: 100%;
    display: block;
    text-align: center;
  }
}
@media screen and (min-width: 720px) {
  .row-news {
    padding: @pm-bg @pm-md;
    display: flex;
    width: 100%;
    font-size: 18px;
    justify-content: space-between;
    transition: background-color .3s;
    cursor: pointer;
    &:hover {
      background-color: #f6f6f6;
      .text-title {
        color: @color-theme;
      }
    }
    & + .row-news {
      border-top: 1px dashed #ccc;
    }
    .text-title {
      font-weight: 500;
      color: #000;
    }
    .text-date {
      font-weight: 300;
      color: #888;
    }
  }
}
@media screen and (max-width: 720px) {

}
</style>
