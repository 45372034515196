<!--
 * @Description  : 法律法规列表
-->
<template>
  <news-list title="法律法规" :articles="laws"></news-list>
</template>

<script>
import NewsList from './news/list'
import { laws } from './../const'
export default {
  components: {
    NewsList
  },
  data () {
    return {
      laws: laws
    }
  }
}
</script>

<style lang="less" scoped>

</style>
