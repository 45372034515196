<!--
 * @Description  : 公司新闻列表
-->
<template>
  <news-list title="公司新闻" :articles="articles"></news-list>
</template>

<script>
import NewsList from './news/list'
import { companyNewsList } from './../const'
export default {
  components: {
    NewsList
  },
  data () {
    return {
      articles: companyNewsList
    }
  }
}
</script>

<style lang="less" scoped>

</style>
